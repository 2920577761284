<template>
  <div class="tw-relative custom-form" :class="{ 'tw-h-12': isDropdown }">
    <label
      @click="$emit('label-click')"
      :for="label"
      v-if="label"
      class="tw-text-black-light custom-form__label tw-font-medium"
      :class="[
        inputActive ? 'custom-form__label--active' : null,
        removeMarginLeft ? 'tw-ml-2' : 'tw-ml-5',
        isDropdown ? 'custom-form__label--z-index' : null,
      ]"
      >{{ label }}
      <span
        class="tw-text-red-error"
        v-if="required && $route.name !== 'SignIn'"
        >*</span
      ></label
    >
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: "InputFieldLabel",
    data() {
      return {};
    },
    emits: ["label-click"],
    props: {
      label: {
        type: String,
      },
      inputActive: {
        type: Boolean,
        required: true,
        default: () => false,
      },
      removeMarginLeft: {
        type: Boolean,
        required: false,
        default: () => false,
      },
      isDropdown: {
        type: Boolean,
        required: false,
        default: () => false,
      },
      required: {
        type: Boolean,
        required: false,
        default: () => {
          return true;
        },
      },
    },
  };
</script>

<style lang="scss" scoped>
  .custom-form {
    &__label {
      position: absolute;
      transition: all 500ms ease-in-out;
      top: 9px;
      color: #a3a8b3;
      padding: 2px 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &--z-index {
        z-index: 1;
      }
      &--active {
        font-size: 12px;
        top: -9px;
        z-index: 2;
        height: 20px;
        display: flex;
        align-items: center;
        background: #fff;
        color: #4f5877;
      }
    }
  }
</style>
